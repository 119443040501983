import logo from './logo.svg';
import sp1 from './sp1.jpeg';
import sp2 from './sp2.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <center>
        <h1> hee joo + pp</h1>
        <img src={sp1} width="50%"></img>
        <img src={sp2} width="50%"></img>
      </center>
    </div>
  );
}

export default App;
